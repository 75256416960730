import PSHDFForm from '../model/PSHDFForm';
const axios = require('axios').default;

const { CosmosClient } = require('@azure/cosmos');

const debug = require('debug');
const log = debug('pshdf:cosmos');
const lerror = debug('pshdf:errors');

const DatabaseId: string = process.env.REACT_APP_COSMOSDB_DATABASE || 'PSHDF';
const FormsContainerId: string = process.env.REACT_APP_COSMOSDB_FORMCONTAINER || 'PSHDFDATA';
const FormsLookupsContainerId: string = process.env.REACT_APP_COSMOSDB_FORMCONFIG || 'PSHDFConfig';

export interface PSHDFLookupSite {
  project: string;
  locations: PSHDFLookupSiteLocation[];
  projectMobiles?: string[];
  email?: string[];
}
export interface PSHDFLookupSiteLocation {
  name: string;
  mobilenumbers: string[];
  email?: string[];
}

export interface PSHDFLookup {
  id:string;
  formType:string;
  sites: PSHDFLookupSite[];
  siteLabel:string;
  locationLabel:string;
  visitorType: string[];
  formTitle: string;
  titleUnderlined:boolean;
  logoUrl: string;
  poweredLogo:string;
  logoWidth?:string;
  showScreenMessage: string;
  defaultMobilenumbers: string[];
  privacyStat: string;
  company:string;
  hasSymptoms :string;
  travel:string;
  inContactWithDiagnosed:string;
  inContactTested:string;
  diagnosed: string;
  quarantineOrder:string;
  style:any;
  deniedIcon:string;
  allowedIcon:string;
  visitorLabel:string;
  workTypes?:string[];
}

export default class PSHDFServiceCosmosdb {
  private _dbclient: any;
  private _db;
  private _formsContainer: any;
  private _formsLookupContainer: any;
  private tokenendpoint = process.env.REACT_APP_COSMOSTOKENENDPOINT;

  public static formNameGlb: string = 'hdf';

  private isInitRequired = (): boolean => {
    if (this._dbclient && this._formsContainer) {
      return false;
    }
    return true;
  };

  public init = async (): Promise<void> => {
    if (!this.isInitRequired()) {
      log('Database already initialized...');
      return;
    }
    log('Loading tokens ...');

    const tokenreq = {
      "cosmosdb":[
        `dbs/${DatabaseId}/colls/${FormsLookupsContainerId}{Read}`,
        `dbs/${DatabaseId}/colls/${FormsContainerId}{All}`
      ]
    }

    await axios
      .post(this.tokenendpoint, tokenreq)
      .then(async response => {
        // handle success
        if (response.data.length <= 0) {
          lerror('No tokens returned, please check the token genertor app or the cleint request.');
          return;
        }

        log('Tokens retrieved.');

        let cosmosClientOption = {};

        let cosmosdbendpoint: string = '';
        let restokens: any = {};
        response.data.cosmosdbTokens.forEach(token => {
          restokens[token.resourceId] = token.token;
          cosmosdbendpoint = token.endpoint;
        });
        cosmosClientOption = {
          resourceTokens: restokens,
          endpoint: cosmosdbendpoint,
          connectionPolicy: {
            enableEndpointDiscovery: false
          }
        };

        this._dbclient = new CosmosClient(cosmosClientOption);

        log('Setting up the database...');

        this._db = this._dbclient.database(DatabaseId);

        log('Setting up the database...done!');
        log('Setting up the PSHDFForms container...');
        const coResponse = await this._db.containers.createIfNotExists({
          id: FormsContainerId
        });
        this._formsContainer = coResponse.container;
        log('Setting up the PSHDFForms container...done!');

        log('Setting up the PSHDFLookups container...');
        const coLookupResponse = await this._db.containers.createIfNotExists({
          id: FormsLookupsContainerId
        });
        this._formsLookupContainer = coLookupResponse.container;
        log('Setting up the PSHDFLookups container...done!');
      })
      .catch(function (error) {
        // handle error
        lerror('Error in getting cosmosdb token: %O', error);
      });
  };

  public saveFormDate = async (form: PSHDFForm): Promise<void> => {
    log('Saving form for location %s...', form.location);

    const { item } = await this._formsContainer.items.upsert(form);
    log(`Form ${item.id} Saved!!!`);
  };

  public getLookupForForm = async (appUrl: string): Promise<PSHDFLookup> => {
    const _form = PSHDFServiceCosmosdb.formNameGlb;
    let _appUrl = appUrl;

    log('Getting form lookup for Covid Form...', _form);

    let item: any;
    const querySpec = {
      query: 'SELECT * FROM c WHERE c.formType = @formType and c.id = @appUrl',
      parameters: [
        {
          name: '@formType',
          value: _form
        },
        {
          name: '@appUrl',
          value: _appUrl
        }
      ]
    };

    const { resources } = await this._formsLookupContainer.items.query(querySpec).fetchAll();

    if (resources.length === 1) {
      item = resources[0];
      log('Item retrivied: %O', item);
      return item as PSHDFLookup;
    }

    lerror('Item not found, maybe an issue with this query? %s', querySpec);

    return {} as PSHDFLookup;
  };
}
