import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import HealthForm from './components/HealthForm';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import {
  BrowserRouter
} from "react-router-dom";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const isDev = (): boolean => {
  if (window.location.hostname === "localhost" 
  || window.location.hostname === "127.0.0.1"
  || window.location.hostname.indexOf("hdfdev.") > -1) return true;

  return false;
}

const debug = require('debug');

debug.enable('pshdf:errors');
if (isDev()) {
  debug.enable('pshdf:*')
};

const gacode = process.env.REACT_APP_GA;
ReactGA.initialize(gacode||'');
ReactGA.pageview(window.location.pathname + window.location.search);

const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
var appInsights:ApplicationInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();
appInsights.trackPageView();

ReactDOM.render(
<BrowserRouter>
    <HealthForm appInsights={appInsights}/>
</BrowserRouter>
,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();