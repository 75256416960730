import { v4 as uuidv4 } from 'uuid';

interface Symptoms{
    fever:boolean;
    cough:boolean;
    shortnessOfBreath:boolean;
    soreThroat:boolean;
}

export default class PSHDFForm{
    id:string;
    formType:string = "hdf";
    deviceId:string | undefined;
    firstname?:string;
    lastname?:string;
    site?:string;
    location?:string;
    date:Date;
    visitorType?:string;
    employer?:string;
    workType?:string;
    workPlace?:string;
    mobile?:string;
    projectMobiles?:string[];
    siteMobiles?:string[];
    projectEmail?:string[];
    siteEmail?:string[];
    submissionTime:number = -1;
    hasSymptoms:boolean = false;
    symptoms?:Symptoms;
    travel:boolean = false;
    diagnosed:boolean = false;
    quarantineOrder:boolean = false;
    inContactWithDiagnosed:boolean = false;
    inContactTested:boolean = false;
    declaration:boolean = false;
    privacy:boolean = false;
    reCaptchaRating?:string;
    result:string;
    company:string;
    constructor(){
        this.id = uuidv4();
        this.date = new Date();
        this.declaration = false;
        this.result = "denied";
        this.company = "presssend";
        this.symptoms = {
            fever: false,
            cough: false,
            shortnessOfBreath: false,
            soreThroat:false,
        }
    }
}