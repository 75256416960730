import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import PSHDFForm from '../model/PSHDFForm';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  Stack,
  IDropdownOption,
  ShimmerElementsGroup,
  ShimmerElementType,
  Fabric,
  Shimmer,
  mergeStyles,
  PrimaryButton,
  DefaultButton,
  Label,
  IStyleFunctionOrObject,
  ILabelStyleProps,
  ILabelStyles,
  ICheckboxStyleProps,
  ICheckboxStyles,
  loadTheme,
  IButtonStyles,
  createTheme,
  getTheme
} from 'office-ui-fabric-react';
import { FormikTextField, FormikCheckbox, FormikDropdown, FormikToggle } from 'formik-office-ui-fabric-react';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Formik, Form, Field, FormikProps, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import Fingerprint2 from 'fingerprintjs2';
import PSHDFServiceCosmosdb, { PSHDFLookup, PSHDFLookupSite } from '../service/Cosmosdb.service';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

enum AppInsEvents {
  FormSubmit = 'FormSubmit',
  ProjectsLoad = 'ProjectsLoad',
  CachedUser = 'CachedUser',
  ProjectInURL = 'ProjectInURL',
  VisitorTypeInURL = 'VisitorTypeInURL',
  WorkPlaceInURL = 'WorkPlaceInURL'

}

const visitorTypeOther = 'Contractor';

const cacheKey = 'presssend-hdf-key';
const debug = require('debug');
const lscache = require('lscache');

const log = debug('pshdf:form');
const lerror = debug('pshdf:errors');

const shimmerWrapperClass = mergeStyles({
  padding: 2,
  selectors: {
    '& > .ms-Shimmer-container': {
      margin: '10px 10px'
    }
  }
});

const confirmationPersonInfoStyle: IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles> = {
  root: {
    padding: '1px'
  }
};

const checkBoxAgreement: IStyleFunctionOrObject<ICheckboxStyleProps, ICheckboxStyles> = {
  root: {
    padding: '25px 0px'
  }
};

const buttonStyle: IButtonStyles = {
  root: {
    height: '50px',
    minWidth: '95px'
  }
};

interface FormState {
  isLoading: boolean;
  allowed: boolean;
  confirmed: boolean;
  submittedData?: PSHDFForm;
  formConfig?: PSHDFLookup;
  projects?: IDropdownOption[];
  visitorTypes?: IDropdownOption[];
  workTypes?: IDropdownOption[];
}

interface FormProps {
  appInsights: ApplicationInsights;
}
initializeIcons();
moment.locale('en-AU');
const dbService: PSHDFServiceCosmosdb = new PSHDFServiceCosmosdb();
//const ausMobilePattern = /(^(04|\+614|00614)\d{8}$)|(^(\+642|00642)\d{7,9}$)/g;
const ausMobilePattern = /(^(\+6){0,1}\d{7,15}$)/g;

const mobileValidationError = 'Must be a valid mobile number';

const HealthForm = (props: FormProps & RouteComponentProps) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  const innerFormSectionStyle = {
    padding: '20px ' + (isTabletOrMobile ? '20px' : '20%')
  };

  let initialForm: PSHDFForm = new PSHDFForm();

  const [formState, setFormState] = useState<FormState>({
    isLoading: true,
    confirmed: false,
    allowed: false
  });

  const cachedUser: any = lscache.get(cacheKey);
  if (cachedUser) {
    initialForm.firstname = cachedUser.firstname.replace(/[^\x20-\x7E]+/g, '');
    initialForm.lastname = cachedUser.lastname.replace(/[^\x20-\x7E]+/g, '');
    initialForm.location = cachedUser.location.replace(/[^\x20-\x7E]+/g, '');
    initialForm.site = cachedUser.site.replace(/[^\x20-\x7E]+/g, '');
    initialForm.visitorType = cachedUser.visitorType.replace(/[^\x20-\x7E]+/g, '');
    initialForm.mobile = cachedUser.mobile.replace(/[^\x20-\x7E]+/g, '');
    initialForm.employer = cachedUser.employer?.replace(/[^\x20-\x7E]+/g, '');
    initialForm.workType = cachedUser.workType?.replace(/[^\x20-\x7E]+/g, '');
    initialForm.workPlace = cachedUser.workPlace?.replace(/[^\x20-\x7E]+/g, '');

    props.appInsights.trackEvent({ name: AppInsEvents.CachedUser });
  }

  const urlparams: URLSearchParams = new URLSearchParams(props.location.search);
  const prjUrl = urlparams.get('site');
  const siteUrl = urlparams.get('location');
  const visitorType = urlparams.get('visitorType');
  const workPlace = urlparams.get('workPlace');
  const disabledParam = urlparams.get('disable') || false;
  const fieldDisabled:boolean = disabledParam.toString().toLowerCase() === "true" ? true : false;

  if (prjUrl && prjUrl != null) {
    log('setting project form url: %s', prjUrl);
    initialForm.site = prjUrl.replace(/[^\x20-\x7E]+/g, '');
    props.appInsights.trackEvent({ name: AppInsEvents.ProjectInURL });
  }

  if (siteUrl && siteUrl != null) {
    log('setting site form url: %s', siteUrl);
    initialForm.location = siteUrl.replace(/[^\x20-\x7E]+/g, '');
  }

  if (visitorType && visitorType != null) {
    log('VisitorType form url: %s', visitorType);
    initialForm.visitorType = visitorType.replace(/[^\x20-\x7E]+/g, '');
    props.appInsights.trackEvent({ name: AppInsEvents.VisitorTypeInURL });
  }

  if (workPlace && workPlace != null) {
    log('WorkPlace form url: %s', workPlace);
    initialForm.workPlace = workPlace.replace(/[^\x20-\x7E]+/g, '');
    props.appInsights.trackEvent({ name: AppInsEvents.WorkPlaceInURL });
  }

  useEffect(() => {
    log('Running useEffect');
    dbService.init().then(() => {
      props.appInsights.startTrackEvent(AppInsEvents.ProjectsLoad);

      dbService
        .getLookupForForm(window.location.hostname || window.location.host)
        .then((lookupdata: PSHDFLookup) => {
          props.appInsights.stopTrackEvent(AppInsEvents.ProjectsLoad);
          log('Form config loaded');
          const th = createTheme(lookupdata.style);
          loadTheme(th);

          let _projects: IDropdownOption[] = [];
          lookupdata.sites.forEach(val => {
            _projects.push({ key: val.project, text: val.project });
          });

          let _visitorType: IDropdownOption[] = [];
          lookupdata.visitorType.forEach(val => {
            _visitorType.push({ key: val, text: val });
          });

          let _workTypes: IDropdownOption[] = [];
          lookupdata.workTypes?.forEach(val => {
            _workTypes.push({ key: val, text: val });
          });

          setFormState({
            ...formState,
            projects: _projects,
            visitorTypes: _visitorType,
            workTypes: _workTypes,
            isLoading: false,
            formConfig: lookupdata
          });
        })
        .catch(error => {
          lerror(error);
          props.appInsights.trackException({ exception: new Error(error) });
        });
    });
  }, []);

  const submitForm = (values: PSHDFForm, helper: FormikHelpers<PSHDFForm>): void => {
    props.appInsights.startTrackEvent(AppInsEvents.FormSubmit);
    log(`Submitting form ${values.id}`);
    const endDate = new Date();
    values.submissionTime = (endDate.getTime() - values.date.getTime()) / 1000;

    Fingerprint2.get({}, (components: Fingerprint2.Component[]) => {
      const fpvalues: Fingerprint2.Component[] = components.map((component: Fingerprint2.Component) => {
        return component.value;
      });

      const murmur: string = Fingerprint2.x64hash128(fpvalues.join(''), 31);
      log('Fingerprint:%s', murmur);
      values.deviceId = murmur;

      if (!values.location) {
        values.location = 'none';
      }

      if (values.visitorType !== visitorTypeOther) {
        values.employer = 'none';
      }

      values.company = formState.formConfig?.company!;

      const cacheObj = {
        firstname: values.firstname,
        lastname: values.lastname,
        mobile: values.mobile,
        site: values.site,
        location: values.location,
        visitorType: values.visitorType,
        employer: values.employer,
        workType: values.workType,
        workPlace: values.workPlace
      };
      lscache.set(cacheKey, cacheObj, process.env.REACT_APP_CACHEEXPMINS);
      log('Saved info in cache: %O', cacheObj);

      if (isPersonAllowed(values)) {
        values.result = 'granted';
      }

      if (values.site) {
        const contacts = getContactsForProject(values.site);
        values.projectMobiles = contacts.mobiles;
        values.projectEmail = contacts.email;
      }

      if (values.location && values.site) {
        const contacts = getContactsForSite(values.site, values.location);

        values.siteMobiles = contacts.mobiles;
        values.siteEmail = contacts.email;
      }

      log('Form data: %O', values);
      dbService
        .saveFormDate(values)
        .then(() => {
          props.appInsights.stopTrackEvent(AppInsEvents.FormSubmit);

          log('Form saved.');
          setFormState({
            ...formState,
            allowed: isPersonAllowed(values),
            confirmed: true,
            submittedData: values
          });
        })
        .catch(error => {
          lerror(error);
          props.appInsights.trackException({ exception: new Error(error) });
        });
    });
  };

  const getSitesForProject = (project?: string): IDropdownOption[] => {
    let _ret: IDropdownOption[] = [];
    const selectProject = formState.formConfig?.sites.find((site: PSHDFLookupSite) => {
      return site.project === project;
    });
    selectProject?.locations.forEach(val => {
      _ret.push({ key: val.name, text: val.name });
    });

    return _ret;
  };

  const formSchema = yup.object().shape({
    firstname: yup.string().required('First Name is required'),
    lastname: yup.string().required('Last Name is required'),
    site: yup.string().required(`${formState.formConfig?.siteLabel} is required`),
    location: yup.string().when('site', (project, schema) => {
      return getSitesForProject(project).length > 0 ? schema.required(`${formState.formConfig?.locationLabel} is required`) : schema;
    }),
    visitorType: yup.string().required('Staff type is required'),
    reCaptchaRating: yup.string().required('Captcha not passed.'),
    mobile: yup.string().matches(ausMobilePattern, mobileValidationError).required('Required'),
    declaration: yup.mixed().oneOf([true], 'The terms and conditions must be accepted.'),
    privacy: yup.mixed().oneOf([true], 'The Privacy consent must be accepted.'),
    employer: yup.string().when('visitorType', { is: visitorTypeOther, then: s => s.required(`Company is required when Staff type is ${visitorTypeOther}`) }),
    workType: yup.string().when('visitorType', { is: visitorTypeOther, then: s => s.required(`Type of work is required when Staff type is ${visitorTypeOther}`) }),
    workPlace: yup.string().when('visitorType', { is: visitorTypeOther, then: s => s.required(`Shop name or number is required when Staff type is ${visitorTypeOther}`) })
  });

  const getContactsForProject = (project: string): any => {
    let _ret: any = { mobiles: [], email: [] };

    const projectMobNumbers: string[] | undefined = formState.formConfig?.sites.find(p => p.project === project)?.projectMobiles;
    const projectEmail: string[] | undefined = formState.formConfig?.sites.find(p => p.project === project)?.email;

    if (projectMobNumbers && projectMobNumbers.length > 0) _ret['mobiles'] = projectMobNumbers;
    if (projectEmail && projectEmail.length > 0) _ret['email'] = projectEmail;

    if (formState.formConfig?.defaultMobilenumbers && formState.formConfig?.defaultMobilenumbers.length > 0) {
      const allmob:string[] = _ret.mobiles.concat(formState.formConfig?.defaultMobilenumbers);
      _ret['mobiles'] = allmob.filter((item: string, index: number, array: string[]) => {
        return array.indexOf(item) === index;
      });
    }

    return _ret;
  };

  const getContactsForSite = (project: string, site: string): any => {
    let _ret: any = { mobiles: [], email: [] };

    const siteMobNumbers: string[] | undefined = formState.formConfig?.sites.find(p => p.project === project)?.locations.find(s => s.name === site)?.mobilenumbers;
    const siteEmail: string[] | undefined = formState.formConfig?.sites.find(p => p.project === project)?.locations.find(s => s.name === site)?.email;

    if (siteMobNumbers && siteMobNumbers.length > 0) _ret['mobiles'] = siteMobNumbers;
    if (siteEmail && siteEmail.length > 0) _ret['email'] = siteEmail;

    return _ret;
  };

  const powered = (): JSX.Element => {
    return (
      <Stack id="powered" horizontal horizontalAlign="end" verticalFill verticalAlign="baseline">
        <img alt="powered" style={{ width: 140, padding: '10px 10px 10px 0px' }} src={formState.formConfig?.poweredLogo}></img>
      </Stack>
    );
  };

  const header = (confirmation: boolean): JSX.Element => {
    return (
      <Stack
        tokens={{ childrenGap: 8 }}
        key="PSHDFFormTitle"
        verticalFill={true}
        verticalAlign="center"
        styles={{
          root: {
            paddingTop: confirmation ? '0px' : '20px',
            paddingBottom: confirmation ? '0px' : '20px'
          }
        }}>
        <div style={innerFormSectionStyle}>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img alt="companylogo" style={{ width: formState.formConfig?.logoWidth ?? 80 }} src={formState.formConfig?.logoUrl}></img>
          </div>
          <div
            className="formTitle"
            style={{
              color: getTheme().palette.white,
              textDecoration: formState.formConfig?.titleUnderlined ? 'underline ' + getTheme().palette.themePrimary : 'unset'
            }}>
            {formState.formConfig?.formTitle}
          </div>
        </div>
      </Stack>
    );
  };

  const renderFinalCheck = (): JSX.Element => {
    const person: PSHDFForm = formState.submittedData || new PSHDFForm();
    return (
      <div
        className="ms-Grid"
        dir="ltr"
        style={{
          backgroundColor: getTheme().palette.neutralPrimary
        }}>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-lg12">
            <Stack tokens={{ childrenGap: 8 }} key="PSHDFFormConfirmation" verticalFill={true} verticalAlign="center">
              {header(true)}
              <div style={{
                        backgroundColor: getTheme().palette.white,
                        borderRadius: '50px 50px 0px 0px'
                    }}>
              <div style={innerFormSectionStyle}>
              <Label styles={{root:{paddingBottom:15}}}>{formState.formConfig?.showScreenMessage}</Label>
              <Stack horizontalAlign="center" >
                {formState.allowed ? (
                  <img alt="granted" className="confirmationImg" src={formState.formConfig?.allowedIcon} />
                ) : (
                  <img alt="denied" className="confirmationImg" src={formState.formConfig?.deniedIcon} />
                )}
                <div className="confirmationDateBox">
                  <div className="confirmationDateDayMonth" style={{color:formState.allowed?'green':'red'}}>{moment(person.date).format('D')}</div>
                  <div className="confirmationDateMonth">{moment(person.date).format('MMMM')}</div>
                  <div className="confirmationDateDayWeek">{moment(person.date).format('dddd')}</div>
                  <div className="confirmationDateTime">{moment(person.date).format('LT')}</div>
                </div>
              </Stack>
              <Stack horizontalAlign="start" >
                <Label styles={confirmationPersonInfoStyle}>
                  <strong>Name:</strong> {person.firstname + ' ' + person.lastname}
                </Label>
                <Label styles={confirmationPersonInfoStyle}>
                  <strong>{formState.formConfig?.siteLabel}:</strong> {person.site}
                </Label>
                {person.location && person.location !== 'none' && (
                  <Label styles={confirmationPersonInfoStyle}>
                    <strong>{formState.formConfig?.locationLabel}:</strong> {person.location}
                  </Label>
                )}
                <Label styles={confirmationPersonInfoStyle}>Mandatory health declaration form completed.</Label>
                {formState.allowed && !formState.submittedData?.hasSymptoms ? (
                  <div>
                    <Label>No flu symptoms declared.</Label>
                  </div>
                ) : (
                  <Label style={{ color: 'red' }} styles={confirmationPersonInfoStyle}>
                    <ul style={{ paddingInlineStart: '20px' }}>
                      <li>You are not allowed to sign on or start work.</li>
                      <li>Contact a health care professional for advice.</li>
                      <li>Speak to your employer about your working arrangements.</li>
                    </ul>
                  </Label>
                )}
              </Stack>
              </div>
              {powered()}
              </div>
            </Stack>
          </div>
        </div>
      </div>
    );
  };

  const isPersonAllowed = (values: PSHDFForm): boolean => {
    const extraFields: boolean = !values.inContactTested && !values.diagnosed && !values.quarantineOrder;

    if (!values.hasSymptoms && !values.inContactWithDiagnosed && !values.travel && extraFields) {
      log('Person Allowed!');
      return true;
    }
    log('Person Denied!');
    return false;
  };

  const _getErrorMessageForMobile = (value: string): string => {
    return value.match(ausMobilePattern) || !value ? '' : mobileValidationError;
  };

  const _getCustomElementsExampleOne = (): JSX.Element => {
    return (
      <div
        // tslint:disable-next-line:jsx-ban-props
        style={{ display: 'flex' }}>
        <ShimmerElementsGroup
          shimmerElements={[
            { type: ShimmerElementType.line, width: 40, height: 40 },
            { type: ShimmerElementType.gap, width: 10, height: 40 }
          ]}
        />
        <ShimmerElementsGroup
          flexWrap={true}
          shimmerElements={[
            { type: ShimmerElementType.line, width: 300, height: 10 },
            { type: ShimmerElementType.line, width: 200, height: 10 },
            { type: ShimmerElementType.gap, width: 100, height: 20 }
          ]}
        />
      </div>
    );
  };

  const _getCustomShimmerElementsThree = (): JSX.Element => {
    return (
      <div
        // tslint:disable-next-line:jsx-ban-props
        style={{ display: 'flex' }}>
        <ShimmerElementsGroup
          width={'90px'}
          shimmerElements={[
            { type: ShimmerElementType.line, height: 80, width: 80 },
            { type: ShimmerElementType.gap, width: 10, height: 80 }
          ]}
        />
        <div
          // tslint:disable-next-line:jsx-ban-props
          style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          <ShimmerElementsGroup
            shimmerElements={[
              { type: ShimmerElementType.circle, height: 40 },
              { type: ShimmerElementType.gap, width: 10, height: 40 }
            ]}
          />
          <ShimmerElementsGroup
            flexWrap={true}
            width={'calc(100% - 50px)'}
            shimmerElements={[
              { type: ShimmerElementType.line, width: '90%', height: 10 },
              { type: ShimmerElementType.gap, width: '10%', height: 20 },
              { type: ShimmerElementType.line, width: '100%', height: 10 }
            ]}
          />
          <ShimmerElementsGroup
            flexWrap={true}
            width={'100%'}
            shimmerElements={[
              { type: ShimmerElementType.line, width: '80%', height: 10, verticalAlign: 'bottom' },
              { type: ShimmerElementType.gap, width: '20%', height: 20 },
              { type: ShimmerElementType.line, width: '40%', height: 10, verticalAlign: 'bottom' },
              { type: ShimmerElementType.gap, width: '2%', height: 20 },
              { type: ShimmerElementType.line, width: '58%', height: 10, verticalAlign: 'bottom' }
            ]}
          />
        </div>
      </div>
    );
  };

  return formState.confirmed ? (
    renderFinalCheck()
  ) : formState.isLoading ? (
    <Fabric
      className={shimmerWrapperClass}
      styles={{
        root: {
          padding: '20px'
        }
      }}>
      <Shimmer customElementsGroup={_getCustomElementsExampleOne()} width={350} />
      <Shimmer customElementsGroup={_getCustomShimmerElementsThree()} width={'90%'} />
    </Fabric>
  ) : (
    <Formik
      initialValues={initialForm}
      onSubmit={submitForm}
      validationSchema={formSchema}
      validateOnChange={true}
      validateOnBlur={false}
      validateOnMount={true}
      render={(formProps: FormikProps<PSHDFForm>) => {
        log('Validation Errors: %o', formProps.errors);

        const sitesOptions = getSitesForProject(formProps.values.site);
        const showSite = sitesOptions.length > 0;
        if (!showSite && formProps.values.location !== 'none') {
          log('Setting location to none.');
          formProps.setFieldValue('location', 'none', false);
        } else if (showSite && formProps.values.location === 'none') {
          log('Setting location to undefined.');
          formProps.setFieldValue('location', undefined, true);
          formProps.setFieldTouched('location', true, true);
          formProps.setFieldError('location', 'Site is required');
        } else if (showSite && !formProps.values.location && !formProps.errors.location) {
          log('Trigger location validation');
          //formProps.setFieldTouched('location', true, true);
          formProps.setFieldError('location', 'Site is required');
        } else if (showSite && sitesOptions.length === 1 && formProps.values.location !== sitesOptions[0].key) {
          formProps.setFieldValue('location', sitesOptions[0].key, true);
          formProps.setFieldTouched('location', true, true);
        }

        return (
          <Form onSubmit={formProps.handleSubmit}>
            <div
              className="ms-Grid"
              dir="ltr"
              style={{
                backgroundColor: getTheme().palette.neutralPrimary
              }}>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-lg12">
                  {header(false)}
                  <Stack
                    tokens={{ childrenGap: 12 }}
                    key="PSHDFForm"
                    styles={{
                      root: {
                        backgroundColor: getTheme().palette.white,
                        borderRadius: '50px 50px 0px 0px'
                      }
                    }}>
                    <div style={{ ...innerFormSectionStyle, paddingTop: 30 }}>
                      <Label>Please complete this online form everyday</Label>
                      <Field name="site">
                        {field => <FormikDropdown {...field} disabled={formProps.isSubmitting || fieldDisabled} label={formState.formConfig?.siteLabel} required={true} options={formState.projects} />}
                      </Field>
                      {showSite && (
                        <Field name="location">
                          {field => <FormikDropdown {...field} disabled={formProps.isSubmitting || fieldDisabled} label={formState.formConfig?.locationLabel} required={true} options={sitesOptions} />}
                        </Field>
                      )}
                      <Field name="visitorType">
                        {field => <FormikDropdown {...field} disabled={formProps.isSubmitting || fieldDisabled} label={formState.formConfig?.visitorLabel} required={true} options={formState.visitorTypes} />}
                      </Field>
                      {formProps.values.visitorType === visitorTypeOther && (
                        <>
                        <Field name="employer">{field => <FormikTextField {...field} disabled={formProps.isSubmitting} label={`Contractor Company (Required if ${visitorTypeOther})`} required={true} />}</Field>
                        <Field name="workType">
                          {field => <FormikDropdown {...field} disabled={formProps.isSubmitting} label="Type of works" required={true} options={formState.workTypes} />}
                        </Field>
                        <Field name="workPlace">{field => <FormikTextField {...field} disabled={formProps.isSubmitting || fieldDisabled} label="Shop Name or Number" required={true} />}</Field>
                        </>
                      )}
                      <Field name="firstname">{field => <FormikTextField {...field} disabled={formProps.isSubmitting} label="First Name" required={true} />}</Field>
                      <Field name="lastname">{field => <FormikTextField {...field} disabled={formProps.isSubmitting} label="Last Name" required={true} />}</Field>
                      <Field name="mobile">
                        {field => (
                          <FormikTextField
                            {...field}
                            disabled={formProps.isSubmitting}
                            label="Mobile Number"
                            placeholder="Please enter a valid mobile number"
                            required={true}
                            onGetErrorMessage={_getErrorMessageForMobile}
                          />
                        )}
                      </Field>
                      <Field name="hasSymptoms">{field => <FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={formState.formConfig?.hasSymptoms} />}</Field>
                      <Stack tokens={{ childrenGap: 5 }} key="PSHDFForm">
                        <Label>Which Symptom?</Label>
                        <Field name="symptoms.fever">{field => <FormikCheckbox {...field} disabled={formProps.isSubmitting || !formProps.values.hasSymptoms} label="Fever" />}</Field>
                        <Field name="symptoms.cough">{field => <FormikCheckbox {...field} disabled={formProps.isSubmitting || !formProps.values.hasSymptoms} label="Cough" />}</Field>
                        <Field name="symptoms.soreThroat">{field => <FormikCheckbox {...field} disabled={formProps.isSubmitting || !formProps.values.hasSymptoms} label="Sore throat" />}</Field>
                        <Field name="symptoms.shortnessOfBreath">
                          {field => <FormikCheckbox {...field} disabled={formProps.isSubmitting || !formProps.values.hasSymptoms} label="Shortness of breath" />}
                        </Field>
                      </Stack>
                      <Field name="travel">{field => <FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={formState.formConfig?.travel} />}</Field>
                      <Field name="inContactWithDiagnosed">
                        {field => <FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={formState.formConfig?.inContactWithDiagnosed} />}
                      </Field>
                      <Field name="inContactTested">
                        {field => <FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={formState.formConfig?.inContactTested} />}
                      </Field>
                      <Field name="diagnosed">{field => <FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={formState.formConfig?.diagnosed} />}</Field>
                      <Field name="quarantineOrder">
                        {field => <FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={formState.formConfig?.quarantineOrder} />}
                      </Field>
                      <Field name="declaration">
                        {field => <FormikCheckbox styles={checkBoxAgreement} {...field} disabled={formProps.isSubmitting} label="I declare that the information I have supplied is true." />}
                      </Field>
                      <Field name="privacy">
                        {field => (
                          <FormikCheckbox
                            {...field}
                            disabled={formProps.isSubmitting}
                            onRenderLabel={() => {
                              return <span style={{ color: 'rgb(50, 49, 48)', marginLeft: '4px' }} dangerouslySetInnerHTML={{ __html: formState.formConfig?.privacyStat || '' }}></span>;
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </Stack>
                  <Stack
                    tokens={{ childrenGap: 8 }}
                    styles={{
                      root: {
                        backgroundColor: getTheme().palette.white
                      }
                    }}>
                    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_reCAPTCHA_SITEKEY}>
                      <GoogleReCaptcha
                        onVerify={token => {
                          formProps.setFieldValue('reCaptchaRating', token, true);
                        }}
                      />
                    </GoogleReCaptchaProvider>
                    <Stack tokens={{ childrenGap: 8 }} horizontal style={{ marginTop: '1em', marginBottom: '1em' }} horizontalAlign="center">
                      <PrimaryButton type="submit" styles={buttonStyle} disabled={formProps.isSubmitting || !formProps.dirty || !formProps.isValid}>
                        Submit
                      </PrimaryButton>

                      <DefaultButton
                        type="button"
                        styles={buttonStyle}
                        onClick={() => {
                          formProps.resetForm();
                        }}
                        disabled={formProps.isSubmitting}>
                        Reset
                      </DefaultButton>
                    </Stack>
                    {powered()}
                  </Stack>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default withRouter(HealthForm);
